import React from "react"
import "../style/about.css"
import Layout from "../components/layout"

const privacyPolicy = () => {
	  return (
		      <Layout>
		        <br /><br /><br /><br />
		        <img src="../Images/lx.png" className="lx-img" />
		        <div className="container pl-5 pr-5 pt-5">
		          {/* PRIVACY POLICY */}
		          <div className="row pb-5">
		            <div className="col-md-12 text-center ">
		              <h4 className="aspireblue">PRIVACY POLICY</h4>
		              <p className="mob-justify lh-2">Visitors to this website will be treated to information that is for general information purposes only. The particulars contained or the details mentioned in respect of the projects/ developments undertaken by the Company including depicting banners/posters of the project/development are being modified in terms of the stipulations/ recommendations under the Real Estate (Regulation and Development) Act 2016 and Rules/ Regulations made thereunder (“RERA”) and accordingly may not be fully in line thereof as of date. While Sumadhura Group has taken enough care to ensure that the information provided on this website is up to date and authentic, readers are requested to make their own enquiry before relying on the information. Sumadhura Group does not offer any warranty on such information, and thereby is not liable for any damage or loss including but not limited to consequential or indirect loss or damage in connection with the use of content provided on the website.</p>

		              <p className="mob-justify lh-2">By accessing and using this website, readers agree with our Disclaimer without any limitation or qualification. Our interactive plans, brochures, floor plans, specifications, designs etc are subject to change without prior notice. Render images, walkthroughs and all computer-generated images are artist’s impressions and are only indicative of actual designs. Readers, under no circumstances, shall consider the information on this website as an advertisement, offer for sale and/or invitation. The content is only meant to provide information to readers about the Sumadhura Group and its various projects, initiatives, CSR activities etc. </p>

		              <p className="mob-justify lh-2">These are general information and are subject to change. To find out more about Sumadhura Group’s projects and developments, please call +91 84319 99 444, mail us at enquiries@sumadhuragroup.com or speak to our sales representatives by visiting our project sites. </p>
		  </div>
		          </div>
		        </div>
		  <script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
 </Layout>
		    )
}

export default privacyPolicy
